// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v0.0.1',
  sessionKey: 'OneApiThYUHsHd',
  menuKey: 'da-menu',
  identityServiceUrl: 'https://login-api.dev.dentistryautomation.com/',
  dentaFlowUrl: 'https://coreapi.dev.dentistryautomation.com',
  logOutUrl: 'https://core.dev.dentistryautomation.com/auth/sign-in',
  baseUrl:'https://oneapi.dev.dentistryautomation.com',
  // baseUrl:'https://localhost:4205',
  apiUrl: 'https://oneapi-api.dev.dentistryautomation.com/api/', 
  oneApiUrl:'https://oneapi-api.dev.dentistryautomation.com',
  
  };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
