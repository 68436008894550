import { environment } from "src/environments/environment";
export const ENDPOINT = {
    IDENTITY_SERVICE: `${ environment.identityServiceUrl }`,
    GENERAL_SERVICE: `${ environment.dentaFlowUrl }/api`,
    BASE_URL:`${environment.oneApiUrl}/api`
};

export const CONTROLLER = {
    AUTHENTICATION: 'authentication',
    DROPDOWN: 'dropdown',
    SETUP: 'setup',
    DATA_FROM_PMS: 'dataFromPms',
    WRITE_TO_PMS: 'writeToPms',
    CLIENT: 'client',
    PRA_LOCATION: 'PRALocation',
    PRA_USER: 'PRAUser',
    DATABASE: 'database',
    PATIENT: 'Patient',
    DATA_SOURCE_CONNECTION: 'dataSourceConnection',
    APPLICATION_ROLE: 'applicationRole',
    USER_ROLE: 'userRole',
    MENUS: 'menus',
    USERS: 'user',
    SOURCE_TYPE: 'sourceType',
    SOURCE_MASTER: 'sourceMaster',
    SOURCE_VERSION: 'sourceVersion',
    PAYOR_MASTER: 'payorMaster',
    PAYOR_CREDENTIALS: 'payorCredentials',
    PAYOR_TAG_MAPPING: 'payorTagMapping',
    CLEARING_HOUSE_MASTER: 'clearingHouseMaster',
    CLEARING_HOUSE_CREDENTIALS: 'clearingHouseCredentials',
    CLEARING_HOUSE_TAG_MAPPING: 'clearingHouseTagMapping',
    DATA_SOURCE: 'dataSource',
    DATA_CONTEXT: 'dataContext',
    CHILD_DATA_CONTEXT_MAPPING: 'childDataContextMapping',
    PHI: 'phi',
    SOURCE_FIELD: 'sourceField',
    DESTINATION_FIELDS: 'destinationField',
    COLUMN_MAPPING: 'columnMapping',
    DATA_EXTRACTION_FREQUENCY: 'dataExtractionFrequency',
    EXTRACTION_SCHEDULE: 'extractionSchedule',
    STATE: 'state',
    TAX_ID_MASTER: 'taxIdMaster',
    TAX_ID_USER_MAPPING: 'taxIdUserMapping',
    CONFIGURATION: 'configuration',
    CONFIG: 'config',
    EXTRACTOR: 'extractor',
    WRITE_BACK: 'writeBack',
    PRODUCT_CATEGORY: 'productCategory',
    PRODUCT_COMPONENT: 'productComponent',
    PRODUCT_SUBSCRIPTION: 'productSubscription',
    TARIFF: 'tariff',
    BAA: 'ClientBaaHistory',
    SCHEDULER: 'scheduler',
    SCHEDULE: 'schedule',
    CLAIM_DETAILS:'ClaimDetails',
    MASTER_DATA:'MasterData',
    DATA_CONFIGURATION:'DataConfiguration',
    GRID_VIEW:'GridView',
    RCM_AGENT:'RcmAgent',
    EOB_DETAILS:'EOBDeatils',
    WEB_SCRAPPER:'WebScrapper',
    REPORT: 'report',
    RCM_DASHBOARD:'RcmDashboard',
    SIGN_UP:'SignUp',
    ONE_API:'oneapi',
    SEARCH:'search',
    OneApiExportFiles:'OneApiExportFiles',
    PRACTICE:'Clinic',
    EXPORT_SCHEDULE_CONFIG:'ExportScheduleConfig',
    EXPORT:'export',
};

export const API = {
    AUTH: {
        SIGN_IN: `${ ENDPOINT.IDENTITY_SERVICE }/Login`,
        REFRESH: `${ ENDPOINT.IDENTITY_SERVICE }/Login/Refresh`,
        SIGN_UP: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.AUTHENTICATION }/signUp`,
        SETUP_PASSWORD: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.AUTHENTICATION }/setupPassword`,
        RESET_PASSWORD: `${ ENDPOINT.IDENTITY_SERVICE }/${ CONTROLLER.SIGN_UP }/resetPassword`,
        FORGOT_PASSWORD: `${ ENDPOINT.IDENTITY_SERVICE}/${ CONTROLLER.AUTHENTICATION }/forgotPassword`,
        FORGOT_EMAIL: `${ ENDPOINT.IDENTITY_SERVICE }/${ CONTROLLER.AUTHENTICATION }/forgotEmail`,
    },
    SETUP: {
        USER: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.USERS }/getCurrentUserDetails`,
        MENUS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SETUP }/getActiveProductMenus`,
        LOCATIONS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SETUP }/getLocations`,
        PRODUCTS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SETUP }/getSubscriptions`,
        LOCATIONS_HISTORY: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SETUP }/userLocationPreferenceSave`,
    },
    GRID: {
        GET: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SETUP }/UserGridPreferenceGet`,
        SET: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SETUP }/UserGridPreferenceSave`
    },

    USER:{
        LOGIN:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.ONE_API }/Login`
    },
    ONE_API:{
        LOAD_ONE_APIS:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.ONE_API }/loadOneAPIs`,
        CLIENT:`${ ENDPOINT.BASE_URL }/Client`,
        apiUrlOneAPIParamsDataBindings: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.ONE_API }/loadOneAPIParamsBindings`,
    },
    PRACTICE:{
        GetPracticeByClientId:`${ ENDPOINT.BASE_URL }/${CONTROLLER.PRACTICE}/GetClinicServerListByClientId`,
    },
    EXPORT_SCHEDULE_CONFIG:{
        getOneApiDetails:`${ ENDPOINT.BASE_URL }/${CONTROLLER.EXPORT_SCHEDULE_CONFIG}/getOneApiDetails`,
        getFrequency:`${ ENDPOINT.BASE_URL }/${CONTROLLER.EXPORT_SCHEDULE_CONFIG}/getFrequency`,
        getAllExtractionScheduleConfig:`${ ENDPOINT.BASE_URL }/${CONTROLLER.EXPORT_SCHEDULE_CONFIG}/getAllExtractionScheduleConfig`,
        save:`${ ENDPOINT.BASE_URL }/${CONTROLLER.EXPORT_SCHEDULE_CONFIG}/Save`,
        update:`${ ENDPOINT.BASE_URL }/${CONTROLLER.EXPORT}/${CONTROLLER.SCHEDULE}/${CONTROLLER.CONFIG}/isActive`,
    },
    OneApiExportFiles: `${ ENDPOINT.BASE_URL }/OneApiExportFiles`,
    DROPDOWN: {
        CHILD_DATA_CONTEXTS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getChildDataContexts`,
        CLEARING_HOUSES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getClearingHouses`,
        CLIENTS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getClients`,
        CLIENT_TYPES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getClientTypes`,
        CLINIC_SERVERS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getClinicServers`,
        CONNECTION_TYPES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getConnectionTypes`,
        COUNTRIES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getCountries`,
        DATA_CONTEXTS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getDataContexts`,
        JOB_FREQUENCIES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getJobFrequencies`,
        MENUS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getMenus`,
        PAYORS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getPayors`,
        PAYOR_TYPES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getPayorTypes`,
        PMS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getPMS`,
        PMS_SERVERS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getPMSServers`,
        PRACTICE_LOCATIONS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getPracticeLocations`,
        PRACTICES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getPractices`,
        PRODUCT_COMPONENTS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getProductComponents`,
        PRODUCTS_CATEGORIES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getProductsCategories`,
        PRODUCT_TYPES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getProductTypes`,
        SCHEDULE_POST_SUCCESS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getSchedulePostSuccess`,
        SOURCES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getSources`,
        SOURCE_TYPES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getSourceTypes`,
         STATES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getStates`,
        STATUS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getStatus`,
        TAX_IDS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getTaxIds`,
        TRANSACTION_TYPES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getTransactionTypes`,
        USER_ROLES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getUserRoles`,
        WEEKLY_OFFS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getWeeklyOffs`,
        APPLICATION_ROLES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/geApplicationRoles`,
        REPORT_CATEGORIES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getReportCategories`,
        REPORT_SQL_OPERATORS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getReportOperators`,
        REPORT_AGGREGATION_FUNCTIONS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getReportAggregationFunctions`,
    },
    DATA_FROM_PMS: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_FROM_PMS }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_FROM_PMS }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_FROM_PMS }/delete`,
    },
    WRITE_TO_PMS: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.WRITE_TO_PMS }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.WRITE_TO_PMS }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.WRITE_TO_PMS }/delete`,
    },
    CLIENT: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CLIENT }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CLIENT }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CLIENT }/delete`,
    },
    
    USER_ROLE: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.USER_ROLE }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.USER_ROLE }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.USER_ROLE }/delete`,
    },
    MENUS: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.MENUS }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.MENUS }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.MENUS }/delete`,
    },
    USERS: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.USERS }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.USERS }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.USERS }/delete`,
    },
      
    TAX_ID_MASTER: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.TAX_ID_MASTER }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.TAX_ID_MASTER }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.TAX_ID_MASTER }/delete`,
    },
    TAX_ID_USER_MAPPING: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.TAX_ID_USER_MAPPING }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.TAX_ID_USER_MAPPING }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.TAX_ID_USER_MAPPING }/delete`,
    },
   
 
    PRODUCT_CATEGORY: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_CATEGORY }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_CATEGORY }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_CATEGORY }/delete`,
    },
    PRODUCT_COMPONENT: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_COMPONENT }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_COMPONENT }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_COMPONENT }/delete`,
    },
    PRODUCT_SUBSCRIPTION: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_SUBSCRIPTION }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_SUBSCRIPTION }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_SUBSCRIPTION }/delete`,
    },


    RCM_GRID :{
        GET_GRID:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.GRID_VIEW }/GetLandingGridView`,
        GET_CLAIM_HISTORY:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.GRID_VIEW }/GetClaimHistory/`,
        GET_FILTER_VALUE:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.GRID_VIEW }/GetDdlFilterColumnDataNew`,
        GET_ALL_GRID:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.GRID_VIEW }/GetAllClaimsByDAStatus`,
        BULK_ASSIGN:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.GRID_VIEW }/BulkAssignToAgent`,
        // GET_All_GRID:`${End}`
    },
    SETTINGS:{SAVE:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.EXPORT }/fileSetting`}
}