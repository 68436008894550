import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { UserSetupService } from 'src/app/modules/user-profile/services/user-setup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {

  error: boolean;
  redirectTo: any;
  private unsubscribe: Subscription[] = [];
  userProfile: any;
  isLoading$: Observable<boolean>;

  constructor(private authService: AuthenticationService, private router: Router, private route: ActivatedRoute, private userSetupService: UserSetupService) {}

  
  async ngOnInit(): Promise<void> {
    if (this.route.snapshot.fragment?.indexOf('error') >= 0) {
      this.error=true; 
      return;    
      
    }

    console.log(window.location.href.includes('validate=false'),window.location.href);
    if (window.location.href.includes('validate=false')) {
      
    }
    
    
    await this.authService.completeAuthentication();
   this.userSetupService.getUserDetails().subscribe(res=>{
      let userProfile = res
      if (userProfile?.pageSetup?.serverUrl)
        {
          //  if baseurl == userProfile?.pageSetup?.serverUrl then ignore
          // if baseurl !== userProfile?.pageSetup?.serverUrl then redirect to serverurl
          if (environment.baseUrl==userProfile?.pageSetup?.serverUrl) {
            console.log('block 1');
            
            if (localStorage.getItem('pageUrl')) {
              this.router.navigate(JSON.parse(localStorage.getItem('pageUrl')));
            console.log('block 1',localStorage.getItem('pageUrl'));

            }else{
              this.router.navigate(['one-api/manage-api']);
            console.log('block 1','one-api/manage-api');

            }  
          }else{
            if (localStorage.getItem('validate')=='false') {
            console.log('block 2');

              if (localStorage.getItem('pageUrl')) {
                this.router.navigate(JSON.parse(localStorage.getItem('pageUrl')));
                console.log('block 2',localStorage.getItem('pageUrl'));

              }else{
                this.router.navigate(['one-api/manage-api']);
                console.log('block 2','one-api/manage-api');

              } 
            }else{
              console.log('block 3');
              window.open(`${ userProfile?.pageSetup?.serverUrl }`, '_self');

            }
          }
        }else{
          if (localStorage.getItem('pageUrl')) {
            this.router.navigate(JSON.parse(localStorage.getItem('pageUrl')));
            console.log('block 4',localStorage.getItem('pageUrl'));
          }else{
            this.router.navigate(['one-api/manage-api']);
            console.log('block 2','one-api/manage-api');
          }  
        }
   })
  //  this.redirectTo = localStorage.getItem('redirectTo')
  //  if (localStorage.getItem('pageUrl')) {
  //   this.router.navigate(JSON.parse(localStorage.getItem('pageUrl')));
  // }else{
  //   this.router.navigate(['one-api/manage-api']);
  // }   
  }

}
